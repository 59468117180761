

const variables = {
    white: '#ffffff',
    green: '#7FBB41',
    grey_dark: '#413F3C',
    grey_mid: '#CCCCCC',
    grey_light: '#E6E6E6',
}

const cssVariables = () => {
    let cssVariables = '';

    Object.entries(variables).forEach((variable) => {
      const [key, value] = variable;
      // console.log(key, value);
      cssVariables += `--${key}: ${value}; `
    })

    return cssVariables;

}



export {
    cssVariables
}