import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
    .header{
    padding:2rem; 
    display: flex;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:8rem;
    z-index: 1000;
    justify-content: space-between;
    align-items: center;
    ${({theme}) => theme.easing('0.5')}  


        .logo{
        width:15rem;
            .logo-header-st0{
            ${({theme}) => theme.easing('0.5')}  
            }
        }

        .header__right{
        display: flex;
        align-items: center;

            
            .linkedIn,
            .instagram{
            
                .icon{
                color:var(--grey_mid);
                font-size:2.2rem;
                margin-right:2rem;
                ${({theme}) => theme.easing('0.5')}  
                }

                &:hover{
                    .icon{
                    color:var(--grey_dark);
                    }
                }
            }

            .linkedIn{
            margin-right:4rem;
            }

        }


        &.toggle{
        background:var(--grey_dark);

            .logo{

                .logo-header-st0{
                fill:var(--white);
                }
            }

            .header__right{
                .linkedIn,
                .instagram{
                    .icon:hover{
                    color:var(--white);
                    }
                }
            }

        }
    }
`

export { Styling }