import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
padding:2rem 4rem;
display: flex;
justify-content: space-between;
${({theme}) => theme.fontSize('10')}  
color:var(--white);
background:var(--grey_dark);

    a{
    color:var(--white);
    text-decoration:none;
    ${({theme}) => theme.easing('0.5')}  

        &:hover{
        color:var(--green);
        }

    }

`

export { Styling }