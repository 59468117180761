import React, {useRef, useEffect} from 'react'
import Logo from "../../../images/logo.inline.svg"
import Navigation from '../Navigation'
import handleScroll from './animations/handleScroll'
import {handleNavToggle} from './animations/nav/handleNavToggle'
import {AiOutlineInstagram} from  'react-icons/ai'
import {FaLinkedinIn} from 'react-icons/fa'
import { Link } from 'gatsby'
import { Styling } from './styled.js'
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
    const data = useStaticQuery(graphql`
      {
        wp {
          siteSettings {
            siteSettings {
                address
                instagram
                googleMapsDirections
                linkedin
                phone
                phoneFormatted
                email
                formText
            }
          }
        }
        menu: wpMenu(name: {eq: "Main Menu"}) {
          id
          count
          menuItems {
            nodes {
              path
              label
            }
          }
        }
      }
    `)

    const settings = data.wp.siteSettings.siteSettings;
    const menu = data.menu.menuItems.nodes;
    const {instagram, linkedin} = settings;
    const header = useRef();


    useEffect((header) => {
      console.log('header useEffect 1');
      if(typeof window !== 'undefined'){
          console.log('header useEffect 2');
            handleNavToggle()
            window.addEventListener('scroll', handleScroll)
        }
        return () => {
            if(typeof window !== 'undefined'){
                window.removeEventListener('scroll', handleScroll)
                // window.removeEventListener('load', handleNavToggle)
            }
        }
    }, [])

    return (
        <>
        <Styling>
            <header className="header">
                <Link to='/'>
                    <Logo className="logo"/>
                </Link>
                <div className="header__right">
                    <a className="instagram" href={instagram} target="_blank" rel="noreferrer noopener">
                        <AiOutlineInstagram className="icon icon-instagram"/>
                    </a>
                    <a className="linkedIn" href={linkedin}  target="_blank" rel="noreferrer noopener">
                        <FaLinkedinIn className="icon icon-linkedIn"/>
                    </a>
                    {/* <NavToggle className="navToggle"/> */}
                </div>
                
            </header>
        </Styling>
          <Navigation menu={menu} contact={settings} />
        </>
    )
  }
  
  export default Header
  
  
