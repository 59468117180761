const handleScroll = (e) => {
    if(typeof document !== 'undefined'){
        const header = document.querySelector('.header')    
        const scroll = e.target.scrollingElement.scrollTop;
        let scrollThreshold = 10;
        if(scroll > scrollThreshold){
            header.classList.add('toggle');
        }else{
            header.classList.remove('toggle');  
        }
    }
}

export default handleScroll;