import React, {useEffect} from "react"
import Header from '../components/global/Header'
import Footer from '../components/global/Footer'
import Credits from '../components/global/Credits'
import { createGlobalStyle, ThemeProvider } from "styled-components"
import {cssVariables} from '../styles/variables'
import {mixins} from '../styles/mixins'
import { globalHistory } from '@reach/router'
import {toggleNav} from '../components/global/Header/animations/nav/handleNavToggle'

const GlobalStyle = createGlobalStyle`

  :root{
    ${cssVariables}
  }

  *{
  box-sizing: border-box;
  }

  html{
      font-size: 62.5%;
  }
  body{
      font-size: 1.6rem;
      font-family: 'gotham--book';
      overflow-x:hidden;
  }
`

export default function Layout(data) {
  useEffect(() => {

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        toggleNav()
      }
    })
  }, [])
  
  return (
    
    <ThemeProvider theme={mixins}>
        <GlobalStyle />
        <Header />
        {data.children}
        <Footer />
        <Credits />
      </ThemeProvider>
  )
}