const mixins = {
    opacity: (opacity) => `opacity: ${opacity};`,
    easing: (time) => `transition: ${time}s all ease-in-out;`,
    cover: () => 'width:100%; height:100%; position:absolute; top:0; left:0;', 
    fontSize: (size) => `font-size: ${size}px; font-size: ${(size / 10) * 1}rem; line-height:${(size / 10) * 1.5}rem;`,
    font_openSans__light: () =>  'font-family: open_sans--light;',
    font_openSans__reg: () =>  'font-family: open_sans--regular;',
    font_gotham__bold: () =>  'font-family: gotham--bold;',
    font_gotham__book: () =>  'font-family: gotham--book;',
    wrapper: () => `max-width: 70vw; position: relative; margin:0 auto;`
}

export {
    mixins
}
