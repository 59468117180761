import React from 'react'
import { Styling } from './styled.js'


export default function Credits({data}) {
    return (
        <Styling>
            <div className="copy">&copy; 3-sphere London Ltd. Co. Reg No</div>  
            <div className="credits"><a href="https://designedbyduo.co.uk" rel="noopenner noreferrer" target="_blank">Designed by Duo</a> | <a href="https://builtbymike.co.uk" rel="noopenner noreferrer" target="_blank">Built by Mike</a></div>
        </Styling>
    )
}
