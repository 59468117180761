import React from 'react'
import styled from 'styled-components'

const Styling = styled.footer`
width:100%;
padding:6rem 2rem 0 2rem;
background:var(--grey_dark);
display: flex;
justify-content: space-between;
flex-wrap: wrap;

    @media only screen and (min-width: 1024px) {
    padding:6rem 2rem 12rem 2rem;
    }

    .logo{
    width:170px;
    }

    .col{
    ${({theme}) => theme.fontSize('16')}  
    position: relative;
    width: 100%;
    margin-bottom: 6rem;
    text-align:center;
    
        /* @media only screen and (min-width: 680px) {
        margin-left: 4rem;
        width: calc(50% - 4rem);
        } */

        @media only screen and (min-width: 1024px) {
        width: calc(25% - 4rem);
        margin-bottom: 0;
        text-align:left;
        }


        ul{
            li{
            position: relative;
            margin-bottom: 0.5rem;
            ${({theme}) => theme.font_openSans__reg()}  
            }
        }

    }
    
  
    .col-2{
    }
    .col-3{
    
    }
    .col-4{
        
    }

    .bg{
    width: 18px;
    height: 18px;
    position: relative;
    display: inline-block;
    z-index: 100;
    top:0.25rem;
    color:var(--green);

        @media only screen and (min-width:1024px) {
        position: absolute;
        left: -3rem;    
        /* display: initial; */
        }

        svg{
        width:110%;
        height:110%;
        }

    }

    a{
    color:var(--white);
    text-decoration:none;
    ${({theme}) => theme.easing('0.5')} 
    width : 100%;
    position: relative;
    display: inline-block;

        @media only screen and (min-width:1024px) {
        display: initial;
        }
        
        &:hover{
        color:var(--green);
        }

    }

`

export { Styling }