
import { gsap } from "gsap";

let isActive = false;
let isFormActive = false;
let isAnimating = false;
const pointerEventsAll = [
    '.nav_left',
    '.nav_left_form',
    '.nav_right',
]
const pointerEvents = [
    '.nav_left',
    '.nav_right',
]

const toggleNav = (target) => {
    console.log('cl')
    if(typeof document !== 'undefined'){
        const greenSphere = document.querySelector('.nav .greenSphereInner')
        const greySphere = document.querySelector('.nav .greySphere')
        const menuNavToggle = document.querySelector('.menuNavToggle')
        const navLeft = Array.from(document.querySelectorAll('.nav .nav_left li'))
        const navRight = Array.from(document.querySelectorAll('.nav .nav_right li'))
        const greySphere2 = document.querySelector('.nav .greySphere2')
        const form = Array.from(document.querySelectorAll('.nav .nav_left_form'))
        const tl = gsap.timeline()

        function animationActive () {
            isAnimating = true;
        }
        function animationInactive () {
            isAnimating = false;
        }
        
            if(isActive === false && target === 'nav'){
                if(isAnimating === false){
                    animationActive()
                    menuNavToggle.classList.add('active');
                    tl.to(pointerEvents, {pointerEvents:'all', duration:0})
                    .to(greenSphere, {scale:1, duration:0.75})
                    .to(navLeft, {opacity:1, x:0, stagger:0.2, duration:0.5, delay: -0.5})
                    .to(greySphere, {scale:1, x:0, y:'-25%', duration:0.5, delay: -1})
                    // .to(greySphere, {x: '68%', scale:1, duration:0.5, delay: -1})
                    .to(navRight, {opacity:1, x:0, stagger:0.2, delay: -0.85, onComplete: animationInactive})
                    isActive = true
                }
            }else{
                
                if(isAnimating === false){
                    if(isFormActive === false){
                        animationActive()
                        tl.to(pointerEvents, {pointerEvents:'none', duration:0})
                        .to(navLeft, {opacity:0, x:'-100%', duration: 0.25})
                        .to(navRight, {opacity:0, x:'100%', duration: 0.25 , delay: -0.25})
                        .to(greySphere, {x: '100%', scale:0.5, duration:0.5})
                        .to(greenSphere, {scale:0, duration:0.75, delay: -0.25,  onComplete: animationInactive})
                        isActive = false
                        setTimeout(function(){
                            menuNavToggle.classList.remove('active');
                        }.bind(this),1000)
                    }else{
                        animationActive()
                        tl.to(pointerEventsAll, {pointerEvents:'none', duration:0})
                        .to(form, {opacity:0, duration:0.25})
                        .to(navRight, {opacity:0, x:'100%', duration: 0.25})
                        .to(greySphere2, {x: '100%', scale:0.5, duration:0.5})
                        .to(greySphere, {x: '100%', scale:0.5, duration:0.5, delay:-0.5})
                        .to(greenSphere, {scale:0, duration:0.5, delay: -0.25,  onComplete: animationInactive})
                        isActive = false
                        setTimeout(function(){
                            menuNavToggle.classList.remove('active');
                        }.bind(this),1000)
                    }
                }
            }
        
    }
}
const toggleGetInTouch = () => {
    isFormActive = true;
    if(typeof document !== 'undefined'){

        const ww = window.innerWidth
        const navLeft = Array.from(document.querySelectorAll('.nav .nav_left li'))
        const greySphere2 = document.querySelector('.nav .greySphere2')
        const form = Array.from(document.querySelectorAll('.nav .nav_left_form'))
        const formRight = document.querySelector('.nav .nav_right')
        const tl = gsap.timeline()
        
        if(ww <= 950){
            tl.to(pointerEventsAll, {pointerEvents:'all', duration:0})
            .to(pointerEvents, {pointerEvents:'none', duration:0})
            .to(navLeft, {opacity:0, x:'-100%', duration: 0.25})
            .to(formRight, {x:'100%', opacity:0, delay:-0.25})
            .to(greySphere2, {x:'16%', scale:1, delay:-0.25})
            .to(form, {opacity:1})
        }else{
            tl.to(pointerEventsAll, {pointerEvents:'all', duration:0})
            .to(navLeft, {opacity:0, x:'-100%', duration: 0.25})
            .to(greySphere2, {x:'16%', scale:1, delay:-0.25})
            .to(form, {opacity:1})
        }
    }
}


const handleNavToggle = (e) => {
    console.log('handleNavToggle loaded 1')
    if(typeof document !== 'undefined'){
        console.log('handleNavToggle loaded 2')
        const navToggle = document.querySelectorAll('.navToggle')
        const getInTouch = document.querySelectorAll('.getInTouch')
        navToggle.forEach(toggle => toggle.addEventListener('mousedown', () => toggleNav('nav')))
        navToggle.forEach(toggle => toggle.addEventListener('touchstart', () => toggleNav('nav')))
        getInTouch.forEach(toggle => toggle.addEventListener('mousedown', () => toggleGetInTouch()))
        getInTouch.forEach(toggle => toggle.addEventListener('touchstart', () => toggleGetInTouch()))
    }
}

export  {
    handleNavToggle,
    toggleNav
}
