import React from 'react'
import Logo from "../../../images/logo--footer.inline.svg"
import parse from 'html-react-parser'
import sphere from '../../../images/sphere.svg'
import {RiMapPinLine} from 'react-icons/ri'
import {FiPhoneCall} from 'react-icons/fi'
import {BsEnvelope} from 'react-icons/bs'
import {AiOutlineInstagram} from 'react-icons/ai'
import {FaLinkedinIn} from 'react-icons/fa'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import { Styling } from './styled.js'


const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        siteSettings {
          siteSettings {
            address
            instagram
            googleMapsDirections
            linkedin
            phone
            phoneFormatted
            email
          }
        }
      }
      menu: wpMenu(name: {eq: "Footer Menu"}) {
        id
        count
        menuItems {
          nodes {
            path
            label
          }
        }
      }
    }
  `)
  
  const settings = data.wp.siteSettings.siteSettings;
  const menu = data.menu.menuItems.nodes;
  const {address, instagram, googleMapsDirections, email, linkedin, phone, phoneFormatted} = settings;

  return (
    <Styling>
        <div className="col-1 col">
            <Link to='/'>
                <Logo className="logo" />
            </Link>
        </div>
        <div className="col-2 col">
            <ul>
                {phone ? <li><div className="bg"><FiPhoneCall /></div><a className="phone" href={`tel:${phoneFormatted}`}>{phone}</a></li> : ""}
                <li><div className="bg"><BsEnvelope /></div><a className="email" href={`mailto:${email}`}>{email}</a></li>
                <li><div className="bg"><AiOutlineInstagram /></div><a className="instagram" href={instagram} target="_blank" rel="noreferrer noopener">Follow us</a></li>
                <li><div className="bg"><FaLinkedinIn /></div><a className="linkedIn" href={linkedin}  target="_blank" rel="noreferrer noopener">Connect with us</a></li>
            </ul>
        </div>
        <div className="col-3 col">
            <div className="bg"><RiMapPinLine /></div>
            <div className="address"><a href={googleMapsDirections} target="_blank" rel="noreferrer noopener">{parse(address)}</a></div>
        </div>
        <div className="col-4 col">
            <div className="bg" style={{ backgroundImage: `url(${sphere})` }}></div>
            <ul>
            {menu.map(item => {
                return <li><Link to={item.path}>{item.label}</Link></li>
            })}
            </ul>
        </div>
    </Styling>
  )
}

export default Footer

