import React, { useState, useRef } from "react";
import parse from "html-react-parser";
import NavToggle from "../../../images/navtoggle.inline.svg";
import { Link, navigate } from "gatsby";
import { Styling } from "./styled.js";
import { RiMapPinLine } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

export default function Navigation({ menu, contact }) {
  const {
    address,
    formText,
    instagram,
    googleMapsDirections,
    email,
    linkedin,
    phone,
    phoneFormatted,
  } = contact;
  const [formData, setFormData] = useState();
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formProjectTitle, setFormProjectTitle] = useState("");
  const formRef = useRef();

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleInput = (e) => {
    if (e.target.name === "name") {
      setFormName(e.target.value);
    }
    if (e.target.name === "email") {
      setFormEmail(e.target.value);
    }
    if (e.target.name === "project_title") {
      setFormProjectTitle(e.target.value);
    }
    setFormData({
      name: formName,
      email: formEmail,
      projectTitle: formProjectTitle,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => navigate("/"))
      .catch((error) => alert(error));

    setFormData();
  };
  return (
    <Styling>
      <NavToggle className="menuNavToggle navToggle" />
      <nav className="nav">
        <div className="nav_left">
          <ul>
            {menu.map((item) => {
              // console.log(item.path)
              return (
                <li>
                  <Link to={item.path === "/homepage/" ? "/" : item.path}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
            <li>
              <button className="getInTouch">Get in touch</button>
            </li>
          </ul>
        </div>
        <div className="nav_left_form">
          <div className="text">{formText}</div>
          <div className="form">
            <form
              id="contact-form"
              name="contact"
              method="POST"
              data-netlify="true"
              ref={formRef}
            >
              <div className="col-50 col">
                <label htmlFor="name">Name</label>
                <input
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => handleInput(e)}
                  type="text"
                  name="name"
                ></input>
              </div>
              <div className="col-50 col">
                <label htmlFor="email">Email</label>
                <input
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => handleInput(e)}
                  type="email"
                  name="email"
                ></input>
              </div>
              <div className="col-90 col">
                <label htmlFor="project_title">Project Title</label>
                <input
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => handleInput(e)}
                  type="project_title"
                  name="project_title"
                ></input>
              </div>
              <div className="col-10 col">
                <button type="submit" onClick={(e) => handleSubmit(e)}>
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="nav_right">
          <ul>
            {phoneFormatted && phone ? (
              <li>
                <div className="bg">
                  <FiPhoneCall />
                </div>
                <a className="phone" href={`tel:${phoneFormatted}`}>
                  {phone}
                </a>
              </li>
            ) : (
              ""
            )}
            <li>
              <div className="bg">
                <BsEnvelope />
              </div>
              <a className="email" href={`mailto:${email}`}>
                {email}
              </a>
            </li>
            <li>
              <div className="bg">
                <AiOutlineInstagram />
              </div>
              <a
                className="instagram"
                href={instagram}
                target="_blank"
                rel="noreferrer noopener"
              >
                Follow us
              </a>
            </li>
            <li>
              <div className="bg">
                <FaLinkedinIn />
              </div>
              <a
                className="linkedIn"
                href={linkedin}
                target="_blank"
                rel="noreferrer noopener"
              >
                Connect with us
              </a>
            </li>
            <li>
              <div className="bg">
                <RiMapPinLine />
              </div>
              <a
                className="address"
                href={googleMapsDirections}
                target="_blank"
                rel="noreferrer noopener"
              >
                {parse(address)}
              </a>
            </li>
          </ul>
        </div>
        <div className="greenSphere">
          <div className="greenSphereInner"></div>
        </div>
        <div className="greySphere"></div>
        <div className="greySphere2 "></div>
      </nav>
    </Styling>
  );
}
