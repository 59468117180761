import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`


    .menuNavToggle{
    width:3.5rem;
    ${({theme}) => theme.easing('0.5')}  
    cursor: pointer;
    position: fixed;
    top:2rem;
    right:2rem;
    z-index: 2000;
    
    /* pointer-events: none;
    opacity: 0; */

        &:hover{
        transform: rotate(45deg);
        }

        path{
        fill:var(--green)
        }

        &.active{
            path{
            fill:var(--white)
            }   
        }
    }

    .nav{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index:1000;
    pointer-events: none;

        .greenSphere{
        position: fixed;
        top:-100vh;
        right:100vh;
        display: block;
        width: 100%;
        height:100%;
        top:calc(-100vh + 3.75rem);
        right:calc(3.75rem + 100vh);
        /* transform:translate(100vh,-100vh); */

            .greenSphereInner{
            width:400vh;
            height:400vh;
            border-radius: 100% 0 0 100%;
            position: fixed;
            background:var(--green);
            content:"";
            transform:scale(0.005);
            /* top:2.5rem;
            right:2.5rem; */
            transform-origin: top right;
            }
        }

        .greySphere{
        width:200vh;
        height:200vh;
        border-radius: 100%;
        position: fixed;
        top:0;
        left:55vw;
        background:var(--grey_dark);
        content:"";
        transform:translate(100%, -25%) scale(0.5);
        display: none;
        
            @media only screen and (min-width: 590px) {
            display: block;
            }

            @media only screen and (min-width: 768px) {
            left:50vw;
            }
            
        }
        
        .greySphere2{
        width:200vh;
        height:200vh;
        border-radius: 100%;
        position: fixed;
        top:0;
        right:0;
        background:var(--grey_dark);
        content:"";
        transform:translate(100%, -25%) scale(0.5);
        /* display: none;
        
            @media only screen and (min-width: 590px) {
            display: block;
            } */
        }
    
        .nav_left{
        width:100%;
        height:100%;
        position: fixed;
        z-index: 1100;
        display: flex;
        justify-content: center;
        align-items: center;
        ${({theme}) => theme.fontSize('30')}  
        ${({theme}) => theme.font_gotham__book()}  
        pointer-events: none;
        text-align:center;
        
            @media only screen and (min-width: 590px) {
            text-align:left;
            width:50%;
            }

            @media only screen and (min-width: 768px) {
            ${({theme}) => theme.fontSize('40')}  
            }

        
            

            ul{
            /* pointer-events: all; */

                li{
                opacity: 0;
                transform: translateX(-100%);
                }
            }

            button,
            a{
            ${({theme}) => theme.easing('0.5')}  
            text-transform: uppercase;
            background:none;
            border:0;
            --webkit-appearance:none;
            cursor: pointer;
            padding:0;

                &:hover{
                color:var(--white);
                }

            }

        }

        .nav_left_form{
        width:100%;
        height:100%;
        position: fixed;
        top:0;
        right:0;
        padding:2rem;
        z-index:2000;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        text-align:center;

            @media only screen and (min-width: 590px) {
                text-align:left;
                width:100%;
                right:0%;
                padding:4rem;
                /* max-width: 640px; */
            }
            @media only screen and (min-width: 950px) {
                text-align:left;
                width:50%;
                right:40%;
                max-width: 640px;
                padding:0;
            }

            .text{
            width: 100%;
            max-width: 670px;
            ${({theme}) => theme.fontSize('28')}  
            color:var(--white);
            ${({theme}) => theme.font_openSans__light()} 
            margin-bottom:4rem ;
            }

            .form{
            width: 100%;

                form{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                ${({theme}) => theme.font_gotham__book()} 
                
                    .col{
                    display:inline-flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    margin-bottom: 2rem;
                    
                        label{
                        width:100%;
                        margin-bottom: 1rem;
                        color:var(--green);
                        text-transform:uppercase;
                        }

                        input{
                        width:100%;
                        padding:1.5rem 2rem;
                        max-height:50px;
                        }

                    }
                    
                    .col-50{
                    width:100%;
                    
                        @media only screen and (min-width: 590px) {
                        width:calc(50% - 1rem);
                        }

                        &:first-child{

                            @media only screen and (min-width: 590px) {
                            margin-right: 2rem;
                            }
                        }

                    }
                    .col-90{
                    width: 100%;
                    
                        @media only screen and (min-width: 590px) {  
                        width:auto;
                        flex-grow: 1;
                        margin-right: 2rem;
                        } 
                    }

                    .col-10{
                    width:100%;

                        @media only screen and (min-width: 590px) {  
                        width:auto;
                        }

                        button{
                            padding:1.5rem 2rem;  
                            border-radius: 0;
                            border:0;
                            height:50px;
                            width: 100%;
                            --webkit-appearance:none;
                            background:var(--green);
                            text-transform: uppercase;
                            cursor: pointer;
                            ${({theme}) => theme.easing('0.5')}  

                            &:hover{
                                background:var(--white);
                            }
                        }

                    }

                }

            }

        }
        
        .nav_right{
        width:40%;
        height:100%;
        position: fixed;
        z-index: 1100;
        right:0;
        justify-content: center;
        align-items: center;
        color:var(--white);
        pointer-events: none;
        display: none;
        
            @media only screen and (min-width: 590px) {
            display: flex;
            }
            
            ul{ 
            ${({theme}) => theme.fontSize('14')}  
            position: relative;

                @media only screen and (min-width: 768px) {
                ${({theme}) => theme.fontSize('16')}  
                }

                li{
                position: relative;
                margin-bottom: 2rem;
                transform: translateX(100%
                    );
                ${({theme}) => theme.font_openSans__reg()}
                opacity:0;

                    a{
                    display: block;
                    }

                }
            }

            .bg{
            width: 18px;
            height: 18px;
            position: absolute;
            left: -3rem;
            z-index: 1000;
            top:0.25rem;
            color:var(--green);

                svg{
                width:110%;
                height:110%;
                }

            }

            a{
            color:var(--white);
            text-decoration:none;
            ${({theme}) => theme.easing('0.5')}  

                &:hover{
                color:var(--green);
                }

            }
        }

        button,
        a{
        color:inherit;
        text-decoration: none;
        }

        &.is_active{
            .nav_left,
            .nav_left_form,
            .nav_right{
                pointer-events: all;
            }
        }

    }
`

export { Styling }
